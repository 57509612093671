import React, { useEffect, useState } from 'react'
import style from './PublicInfoDetail.module.less'
import logo from '@/assets/img/title.png'
import { $get } from '@/assets/js/request'
import { awaitWrap, formatDate } from '@/assets/js/tool'
import { Button, ConfigProvider, Empty, Skeleton } from 'antd'
import zhCN from 'antd/es/locale/zh_CN'
import qs from 'querystring'
import icon1 from '@/assets/img/mine-info-icon1.png'
import icon3 from '@/assets/img/mine-info-icon3.png'
import icon4 from '@/assets/img/mine-info-icon4.png'
import icon5 from '@/assets/img/mine-info-icon5.png'
import classNames from 'classnames'
import { useHistory } from 'react-router-dom'
import { DoubleLeftOutlined } from '@ant-design/icons'

const qMap = {
  qualification01: '从事仲裁工作满8年',
  qualification02: '从事律师工作满8年',
  qualification03: '曾任审判员满8年',
  qualification04: '从事法律研究、教学工作具有高级职称',
  qualification05: '具有法律知识、从事经济贸易专业工作并具有高级职称或具有同等专业水平'
}

async function getDetailRequest (id) {
  const res = await $get('/api/arbitrator/getOpenArbitratorDetail', {
    params: {
      id
    }
  })
  return res.data
}

async function getDetail (id, setLoading, setData) {
  setLoading(true)
  const [e, d] = await awaitWrap(getDetailRequest(id))
  setLoading(false)
  if (e === null && d !== null) {
    setData(d)
  }
}

function Main ({ location }) {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState(null)
  const [isInner, setInner] = useState(false)
  const [isMobile, setIsMobile] = useState(false)
  const h = useHistory()
  useEffect(() => {
    const o = qs.parse(location.search.slice(1))
    if (o.id) {
      getDetail(o.id, setLoading, setData)
    }
    setInner(o.inner === '1')
  }, [location])
  useEffect(() => {
    const u = navigator.userAgent
    const o = document.querySelector('meta[name="viewport"]')
    if (!!u.match(/AppleWebKit.*Mobile.*/) && u) {
      setIsMobile(true)
      o.setAttribute('content', 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0')
    }
  }, [])
  return (
    <ConfigProvider locale={zhCN}>
      <div className={classNames(style.container, { [style.mobile]: isMobile })}>
        {
          !isInner &&
            <>
              <div className={style.header}>
                <div className={style['header-nav']}>
                  <a href='http://service.xmac.org.cn/'>
                    <img className={style['header-title-img']} src={logo} alt='' />
                  </a>
                  <a href='http://service.xmac.org.cn/guide.html' className={style['header-to-guide']}>申请立案指引</a>
                </div>
              </div>
              <div className={style.canvas}>
                <div className={style['canvas-title']}>仲裁员 ｜ 数字名片</div>
              </div>
            </>
        }
        <div className={classNames(style['info-content'], { [style.inner]: isInner })}>
          {
            isInner && <div className={style['btn-line']}><Button onClick={() => h.goBack()} icon={<DoubleLeftOutlined />}>返回</Button></div>
          }
          <Skeleton loading={loading} active>
            {
              data ? <DetailInfo data={data} /> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            }
          </Skeleton>
        </div>
        {
          !isInner && (
            <div className={style.footer}>
              <span>©2020 service.xmac.org.cn <a href='https://beian.miit.gov.cn/'>闽ICP备12022941号-1</a></span>
            </div>
          )
        }
      </div>
    </ConfigProvider>
  )
}

function DetailInfo ({ data }) {
  return (
    <div>
      <div className={style['name-line']}>{data.name}（仲裁员编号：{data.idno || '无'}）</div>
      <div className={style['other-info-line']}>
        {data.gender && <span>{data.gender}</span>}
        {data.nation && <span>{data.nation}</span>}
        {data.political && <span>{data.political}</span>}
        {data.country && <span>{data.country}</span>}
      </div>
      <div className={style['icon-line']}>
        {
          data.city && (
            <div className={style['icon-cell']}>
              <img src={icon1} />
              <span>居住地 - {data.city}</span>
            </div>
          )
        }
        <div className={style['icon-cell']}>
          <img src={icon3} />
          <span>{data.retire !== 1 ? '在职' : '非在职'}</span>
        </div>
        {
          data.academic_title && (
            <div className={style['icon-cell']}>
              <img src={icon4} />
              <span>{data.academic_title}</span>
            </div>
          )
        }
        {
          data.language && (
            <div className={style['icon-cell']}>
              <img src={icon5} />
              <span>{data.language}</span>
            </div>
          )
        }
      </div>
      {data.professional instanceof Array && data.professional.length > 0 && (
        <>
          <div className={style['block-title']}>
            <span>擅长专业</span>
          </div>
          <Tree data={data.professional || []} />
        </>
      )}
      {
        data.educational && (
          <>
            <div className={style['block-title']}>
              <span>学历</span>
            </div>
            <div className={style['block-content']}>
              {data.educational || '无'}
            </div>
          </>
        )
      }
      {
        data.qualification_info instanceof Array && data.qualification_info.length > 0 && (
          <>
            <div className={style['block-title']}>
              <span>仲裁员任职资格</span>
            </div>
            <div className={style.qualifaction}>
              {
                data.qualification_info.map((li, i) => {
                  return (
                    <div className={style['qualifaction-line']} key={i}>
                      <Qualication data={li} />
                    </div>
                  )
                })
              }
            </div>
          </>
        )
      }
      {
        data.achievement && (
          <>
            <div className={style['block-title']}>
              <span>学术成果</span>
            </div>
            <div className={style['block-content']}>
              {data.achievement || '无'}
            </div>
          </>
        )
      }
      {
        data.research_tag && (
          <>
            <div className={style['block-title']}>
              <span>研究领域</span>
            </div>
            <div className={style['block-content']}>
              {data.research_tag || '无'}
            </div>
          </>
        )
      }
    </div>
  )
}

function Tree ({ data = [] }) {
  return (
    <div className={style['tree-list']}>
      {
        data.map((li, i) => {
          return (
            <div className={style.leaf} key={i}>
              <div className={style['leaf-text']}>{li.title}</div>
              {li.children instanceof Array && li.children.length > 0 && <Tree data={li.children} />}
            </div>
          )
        })
      }
    </div>
  )
}

function Qualication ({ data }) {
  const list = [qMap[data.qualification]]
  if (['qualification01', 'qualification02', 'qualification03'].includes(data.qualification)) {
    const b = formatDate(data.begin_datetime, 'YYYY.MM')
    let e = '至今'
    if (data.end_datetime) {
      e = formatDate(data.end_datetime, 'YYYY.MM')
    }
    list.push(`${b} - ${e}`)
    if (data.structure) {
      list.push(data.structure)
    }
  } else if (['qualification04', 'qualification05'].includes(data.qualification)) {
    list.push(formatDate(data.begin_datetime, 'YYYY.MM'))
    if (data.structure_post) {
      list.push(data.structure_post)
    }
  }
  return list.map((li, i) => {
    return <span key={i}>{li}</span>
  })
}

export default Main
