import React from 'react'
import Component, { mapStateToProps, mapDispatchToProps } from '../components/Component'
import { connect } from 'react-redux'
import { Empty, List, message, Spin } from 'antd'
import moment from 'moment'
import style from './OpenList.module.less'

class OpenList extends Component {
  state = {
    loading: false,
    list: [],
    currentMonth: 0,
    currentYear: 0,
    nextMonth: 0,
    nextYear: 0,
    currentList: [],
    nextList: []
  }

  // 获取约庭信息
  getOpenList () {
    this.setState({ loading: true })
    this.$get('/api/arbitrator/hearingroom/getArbitratorBooking').then(res => {
      this.setState({ loading: false })
      if (res.status === 1) {
        this.formatList(res.data)
      } else {
        message.warning(res.message)
      }
    }).catch(() => {
      this.setState({ loading: false })
    })
  }

  formatList (list) {
    list.sort((a, b) => a.begin_datetime - b.begin_datetime)
    list = list.map(li => {
      const beginTime = moment(li.begin_datetime * 1000)
      const begin = beginTime.format('HH:mm')
      const end = moment(li.end_datetime * 1000).format('HH:mm')
      const weekday = beginTime.day()
      li.time = `${begin}~${end}`
      li.weekday = ['日', '一', '二', '三', '四', '五', '六'][weekday]
      li.date = beginTime.format('D')
      li.month = beginTime.format('MM')
      return li
    })
    this.setState({
      currentList: list.filter(li => li.month === this.state.currentMonth),
      nextList: list.filter(li => li.month === this.state.nextMonth)
    })
  }

  // 格式化当前日期
  formatCurrentAndNextDate () {
    const d = moment()
    const currentYear = d.format('YYYY')
    const currentMonth = d.format('MM')
    d.add(1, 'M')
    const nextYear = d.format('YYYY')
    const nextMonth = d.format('MM')
    this.setState({
      currentYear,
      currentMonth,
      nextYear,
      nextMonth
    })
  }

  // 前往详情
  goToDetail (id) {
    this.props.history.push(`/admin/detail/${id}`)
  }

  componentDidMount () {
    this.formatCurrentAndNextDate()
    this.getOpenList()
  }

  renderItem (data, isNext) {
    const s = [style.card]
    if (isNext) {
      s.push(style.next)
    }
    return (
      <List.Item>
        <div className={s.join(' ')} onClick={() => this.goToDetail(data.caseid)}>
          <div className={style['card-header']}>
            <div className={style['card-header-time']}><span>{data.date}日</span></div>
            <div className={style['card-header-title']}>
              <span>{data.hearingroom_name}</span>
              <span>{data.time}</span>
            </div>
          </div>
          <div className={style['card-body']}>
            <div className={style['card-body-time']}><div>周</div><div>{data.weekday}</div></div>
            <div className={style['card-body-content']}>
              <div className={style['card-line']}>
                <span>案件编号</span>
                <span>{data.caseno_desc}</span>
              </div>
              <div className={style['card-line']}>
                <span>办案秘书</span>
                <span>{data.operator}</span>
              </div>
              <div className={style['card-line']}>
                <span>开庭人员</span>
                <span>{data.arbitrators}</span>
              </div>
            </div>
          </div>
        </div>
      </List.Item>
    )
  }

  renderCurrent () {
    if (!this.state.loading && this.state.currentList.length) {
      return (
        <>
          <div className={style['header-block']}>
            <div className={style['header-title']}>{`${this.state.currentYear}年${this.state.currentMonth}月`}</div>
            <div className={style['header-sub-title']}>本月开庭</div>
          </div>
          <List
            className={style.list}
            loading={this.state.loading}
            grid={{ gutter: 24, column: 2 }}
            dataSource={this.state.currentList}
            renderItem={item => this.renderItem(item)}
          />
        </>
      )
    }
  }

  renderNext () {
    if (!this.state.loading && this.state.nextList.length === 0) {
      return (
        <>
          <div className={style['header-block']}>
            <div className={style['header-title']}>{`${this.state.nextYear}年${this.state.nextMonth}月`}</div>
            <div className={style['header-sub-title']}>下月开庭</div>
          </div>
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        </>
      )
    }
    if (!this.state.loading && this.state.nextList.length) {
      return (
        <>
          <div className={style['header-block']}>
            <div className={style['header-title']}>{`${this.state.nextYear}年${this.state.nextMonth}月`}</div>
            <div className={style['header-sub-title']}>下月开庭</div>
          </div>
          <List
            className={style.list}
            loading={this.state.loading}
            grid={{ gutter: 24, column: 2 }}
            dataSource={this.state.nextList}
            renderItem={item => this.renderItem(item, true)}
          />
        </>
      )
    }
  }

  renderLoading () {
    if (this.state.loading) {
      return (
        <div className={style.loading}>
          <Spin />
        </div>
      )
    }
  }

  render () {
    return (
      <div className={style.container}>
        {this.renderLoading()}
        {this.renderCurrent()}
        {this.renderNext()}
      </div>
    )
  }
}

const mapState = mapStateToProps(function (state) {
  return {
    userInfo: state.basic.userInfo
  }
})

const mapDispatch = mapDispatchToProps(function (dispatch) {
  return {

  }
})
export default connect(mapState, mapDispatch)(OpenList)
