import React, { Component } from 'react'
import PropTypes from 'prop-types'
import style from './Modal.module.less'

export default class Modal extends Component {
  render () {
    return (
      <div className={style.container}>
        {this.props.title ? (
          <div className={style.header}>
            <div className={style['back-btn']} onClick={() => this.props.back()}>
              <img className={style['back-btn-icon']} src={require('../../assets/img/back-arrow.png')} />
            </div>
            <div className={style.title}>{this.props.title}</div>
          </div>
        ) : null}
        <div>
          {this.props.children}
        </div>
      </div>
    )
  }
}

Modal.propTypes = {
  title: PropTypes.string,
  back: PropTypes.func
}

Modal.defaultProps = {
  title: '',
  back: function () {}
}
