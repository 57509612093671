import React from 'react'
import Admin from '../views/Admin.js'
import Login from '../views/Login'
import PublicInfo from '../views/PublicInfo'
import PublicInfoDetail from '../views/PublicInfoDetail'
import LoginValidate from '../views/LoginValidate/Index.js'
import MediaPlayer from '../views/MediaPlayer.js'
import { Redirect } from 'react-router-dom'

export const RouterConfig = [
  {
    path: '/login',
    component: Login
  },
  {
    path: '/admin*',
    component: Admin
  },
  {
    path: '/public-info',
    component: PublicInfo
  },
  {
    path: '/public-info-detail',
    component: PublicInfoDetail
  },
  {
    path: '/login-validate',
    component: LoginValidate
  },
  {
    path: '/media',
    component: MediaPlayer
  },
  {
    path: '/*',
    render: () => {
      return <Redirect to='/admin' />
    }
  }

  // {
  //   path: '/apply-for-a-deferment',
  //   component: ApplyForADeferment,
  //   sceneConfig: {
  //     enter: 'from-bottom',
  //     exit: 'to-bottom'
  //   }
  // },
]
