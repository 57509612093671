import React, { useCallback, useEffect, useMemo, useState } from 'react'
import style from './PublicInfo.module.less'
import logo from '@/assets/img/title.png'
import { $get } from '@/assets/js/request'
import { awaitWrap, formatDate } from '@/assets/js/tool'
import { message, Table, ConfigProvider, Form, Row, Col, Input, TreeSelect, Cascader, Select, Space, Button, List, Pagination, Skeleton, Empty } from 'antd'
import zhCN from 'antd/es/locale/zh_CN'
import { getProvinceCityTree } from '@/assets/js/area'
import { getTreeRequest } from './ajax'
import Dictionary from '@/assets/js/dictionary'
import { useLocation } from 'react-router-dom'
import qs from 'querystring'
import classNames from 'classnames'

const { Item, useForm } = Form
const { Option } = Select
const areas = getProvinceCityTree()

function getColumns (config, isInner) {
  const keys = Object.keys(config)
  const list = keys.map(key => {
    let width = '200px'
    if (key === 'city') {
      width = '80px'
    } else if (key === 'gender') {
      width = '50px'
    } else if (key === 'occupation') {
      width = '100px'
    }
    return {
      title: config[key],
      dataIndex: key,
      key: key,
      width,
      render: (v, line) => {
        const arr = key.split(',')
        const res = []
        arr.forEach(a => {
          if (line[a]) {
            res.push(line[a])
          }
        })
        return res.join(' ')
      }
    }
  })
  list.unshift({
    title: '序号',
    dataIndex: 'order',
    key: 'order',
    width: 60,
    align: 'center'
  })
  list.push({
    title: '操作',
    dataIndex: 'id',
    key: 'id',
    width: '60px',
    render: (v) => {
      return <a href={`/public-info-detail?id=${v}&inner=${isInner ? 1 : 0}`} target={isInner ? '_self' : '_blank'} rel='noopener noreferrer'>详情</a>
    }
  })
  return list
}

async function getInfoRequest (id, current, pageSize, condition) {
  const res = await $get(`/api/arbitrator/getOpenArbitratorHistoryList/${id}`, {
    params: {
      current,
      page_size: pageSize,
      condition: JSON.stringify(condition)
    }
  })
  if (res.status !== 1) {
    message.error(res.message)
    throw new Error(res.message)
  }
  return res.data
}

async function getInfo (id, current, pageSize, condition, setConfig, setList, setTotal, setLoading) {
  setLoading(true)
  const [e, d] = await awaitWrap(getInfoRequest(id, current, pageSize, condition))
  setLoading(false)
  if (e === null && d !== null) {
    setConfig(formatConfig(d.config))
    d.list.forEach((li, i) => {
      li.order = (current - 1) * pageSize + i + 1
    })
    setList(d.list)
    setTotal(d.total)
  }
}

function formatConfig (d) {
  const res = {}
  let list = []
  let ids = d.value
  try {
    list = JSON.parse(d.default_value)
    ids = JSON.parse(ids)
  } catch (e) {
    console.error(e)
  }
  list.filter(li => li.value !== 'id').forEach(li => {
    if (ids.includes(li.id)) {
      res[li.value] = li.title
    }
  })
  return res
}

async function initTree (setTreeData) {
  const [e, d] = await awaitWrap(getTreeRequest())
  if (e === null && d !== null) {
    setTreeData(d)
  }
}

async function submitHandle (form, setCondition) {
  const condition = []
  if (form.name !== undefined && form.name !== '') {
    condition.push({ k: 'name', o: 'like', v: `%${form.name}%` })
  }
  if (form.address instanceof Array && form.address.length > 0) {
    condition.push({ k: 'province', o: '=', v: form.address[0] })
    condition.push({ k: 'city', o: '=', v: form.address[1] })
  }
  if (form.professional_tag !== undefined && form.professional_tag !== '' && form.professional_tag !== '全部') {
    condition.push({ k: 'professional_tag', o: 'like', v: `%${form.professional_tag}%` })
  }
  if (form.gender !== undefined && form.gender !== '') {
    condition.push({ k: 'gender', o: '=', v: form.gender })
  }
  if (form.occupation2 !== undefined && form.occupation2 !== '') {
    condition.push({ k: 'occupation2', o: 'like', v: `%${form.occupation2}%` })
  }
  setCondition(condition)
}

async function getVersionRequest () {
  const res = await $get('/api/arbitrator/getPublishHistory')
  if (res.status !== 1) {
    message.error(res.message)
    throw new Error(res.message)
  }
  return res.data
}

async function initVersion (setVersions, setVersionLoading, setVersionid) {
  setVersionLoading(true)
  const [e, d] = await awaitWrap(getVersionRequest())
  setVersionLoading(false)
  if (e === null && d !== null) {
    if (d instanceof Array && d.length > 0) {
      setVersions(d)
      setVersionid(d[0].id)
    }
  }
}

function Main () {
  const location = useLocation()
  const [list, setList] = useState([])
  const [current, setCurrent] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [total, setTotal] = useState(0)
  const [loading, setLoading] = useState(false)
  const [config, setConfig] = useState({})
  const [occupationList, setOccupationList] = useState([])
  const [treeData, setTreeData] = useState([])
  const [condition, setCondition] = useState([])
  const [version, setVersion] = useState(Date.now())
  const [formRef] = useForm()
  const [isMobile, setIsMobile] = useState(false)
  const [versions, setVersions] = useState([])
  const [versionLoading, setVersionLoading] = useState(false)
  const [versionid, setVersionid] = useState('')
  const versionOptions = useMemo(() => {
    return versions.map(li => {
      return {
        label: li.title,
        value: li.id
      }
    })
  }, [versions])
  const currentVersion = useMemo(() => {
    if (versionid && versions.length > 0) {
      const o = versions.find(li => li.id === versionid)
      if (o) {
        return o
      }
    }
    return {}
  }, [versionid, versions])
  const maxWidth = useMemo(() => {
    const keys = Object.keys(config)
    let i = 0
    keys.forEach(key => {
      const t = config[key]
      if (t) {
        i = i > t.length ? i : t.length
      }
    })
    return i * 15
  }, [config])
  const span = useMemo(() => {
    return isMobile ? 24 : 8
  }, [isMobile])
  const isInner = useMemo(() => {
    const s = location.search.slice(1)
    const o = qs.parse(s)
    return o.inner === '1'
  }, [location])
  const initFn = useCallback(() => {
    getInfo(versionid, current, pageSize, condition, setConfig, setList, setTotal, setLoading)
  }, [versionid, current, pageSize, condition])
  // useEffect(() => {
  //   getInfo(currentVersion.id, 1, 10, [], setConfig, setList, setTotal, setLoading)
  // }, [currentVersion])
  useEffect(() => {
    const d = new Dictionary()
    d.init('仲裁员职业分类2')
      .then(() => {
        setOccupationList(d.getList('仲裁员职业分类2').map(li => li.title))
      })
    initTree(setTreeData).catch(e => console.error(e))
    // initAreasRequest(formRef, setAreaMap, setInitialValues, onChange)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  useEffect(() => {
    const u = navigator.userAgent
    const o = document.querySelector('meta[name="viewport"]')
    if (!!u.match(/AppleWebKit.*Mobile.*/) && u) {
      setIsMobile(true)
      o.setAttribute('content', 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0')
    }
  }, [])
  useEffect(() => {
    initVersion(setVersions, setVersionLoading, setVersionid)
  }, [])
  useEffect(() => {
    if (versionid) {
      initFn()
    }
  }, [initFn, version, versionid])
  if (versionLoading) {
    return (
      <div className={classNames(style.loading, { [style.inner]: isInner })}>
        <Skeleton active />
      </div>
    )
  }
  if (versionid === '') {
    return (
      <div className={classNames(style.loading, { [style.inner]: isInner })}>
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      </div>
    )
  }
  return (
    <ConfigProvider locale={zhCN}>
      <div className={classNames(style.container, { [style.mobile]: isMobile })}>
        {
          !isInner &&
            <>
              <div className={style.header}>
                <div className={style['header-nav']}>
                  <a href='http://service.xmac.org.cn/'>
                    <img className={style['header-title-img']} src={logo} alt='' />
                  </a>
                  <a href='http://service.xmac.org.cn/guide.html' className={style['header-to-guide']}>申请立案指引</a>
                </div>
              </div>
              <div className={style.canvas}>
                <div className={style['canvas-title']}>仲裁员信息公开</div>
              </div>
            </>
        }
        <div className={classNames(style['public-notice-block'], { [style.inner]: isInner })}>
          <div className={classNames(style['public-notice'], { [style.inner]: isInner })}>
            <div className={classNames(style.versions, { [style.inner]: isInner })}>
              <Select options={versionOptions} placeholder='请选择' value={versionid} onChange={e => setVersionid(e)} />
            </div>
            <div>
              <span>{currentVersion.content}</span>
            </div>
            <div className={style['public-notice-date']}>{formatDate(currentVersion.publish_datetime, 'YYYY年MM月DD日')}</div>
          </div>
        </div>
        <div className={classNames(style['search-block'], { [style.inner]: isInner })}>
          <div className={style['search-block-inner']}>
            <Form form={formRef} className={classNames({ [style.form]: isMobile })} labelCol={{ flex: '88px' }} onFinish={d => { submitHandle(d, setCondition); setCurrent(1); setVersion(Date.now()) }}>
              <Row gutter={56}>
                <Col span={span}>
                  <Item name='name' label='姓名'>
                    <Input placeholder='请输入' allowClear />
                  </Item>
                </Col>
                <Col span={span}>
                  <Item label='性别' name='gender'>
                    <Select className={style.full} placeholder='请选择' allowClear>
                      <Option value=''>全部</Option>
                      <Option value='男'>男</Option>
                      <Option value='女'>女</Option>
                    </Select>
                  </Item>
                </Col>
                <Col span={span}>
                  <Item label='省市' name='address'>
                    <Cascader options={areas} placeholder='请选择' />
                  </Item>
                </Col>
                <Col span={span}>
                  <Item label='职业分类' name='occupation2'>
                    <Select className={style.full} placeholder='请选择' showSearch allowClear>
                      {
                        occupationList.map(li => <Option key={li} value={li}>{li}</Option>)
                      }
                    </Select>
                  </Item>
                </Col>
                <Col span={span}>
                  <Item label='擅长专业' name='professional_tag'>
                    <TreeSelect treeData={treeData} placeholder='请选择' fieldNames={{ label: 'title', value: 'id' }} allowClear showSearch />
                  </Item>
                </Col>
                <Col span={span}>
                  <div className={style.bts}>
                    <Space size='middle'>
                      <Button type='primary' htmlType='submit'>搜索</Button>
                      <Button htmlType='button' onClick={() => { formRef.resetFields(); formRef.submit() }}>重置</Button>
                    </Space>
                  </div>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
        <div className={style.body}>
          {
            isMobile ? (
              <div className={style.list}>
                <List
                  loading={loading} dataSource={list} renderItem={(li, i) => {
                    const keys = Object.keys(config)
                    const width = `${maxWidth + 10}px`
                    return (
                      <div className={style.item} key={i}>
                        <div className={style.line}>
                          <span className={style.label} style={{ width }}>序号</span>
                          <span className={style.value}>{li.order}</span>
                        </div>
                        {
                          keys.map((key, i) => {
                            return (
                              <div key={i} className={style.line}>
                                <span className={style.label} style={{ width }}>{config[key]}</span>
                                <span className={style.value}>{li[key] || '无'}</span>
                              </div>
                            )
                          })
                        }
                        <div className={style['detail-btn']}>
                          <Button type='link' href={`/public-info-detail?id=${li.id}&inner=${isInner ? 1 : 0}`} target='_self'>查看详情</Button>
                        </div>
                      </div>
                    )
                  }}
                />
                <div className={style.pagiantion}>
                  <Pagination
                    current={current} pageSize={pageSize} size='small' total={total} showTotal={total => `共 ${total} 条记录`} onChange={(num) => {
                      setCurrent(num)
                      setVersion(Date.now())
                    }}
                  />
                </div>
              </div>
            ) : (
              <Table
                rowKey='order'
                columns={getColumns(config, isInner)}
                loading={loading}
                dataSource={list}
                pagination={{ current, pageSize, total, showTotal: total => `共 ${total} 条记录` }}
                onChange={(pagination) => {
                  setCurrent(pagination.current)
                  setPageSize(pagination.pageSize)
                  setVersion(Date.now())
                }}
                tableLayout='fixed'
                bordered
              />
            )
          }
        </div>
        {
          !isInner && (
            <div className={style.footer}>
              <span>©2020 service.xmac.org.cn <a href='https://beian.miit.gov.cn/'>闽ICP备12022941号-1</a></span>
            </div>
          )
        }
      </div>
    </ConfigProvider>
  )
}

export default Main
