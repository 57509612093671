import { $get, $post } from '@/assets/js/authRequest'

export async function getRequest (id) {
  const res = await $get(`/api/arbitrator/course/${id}`)
  return res.data
}

export async function getLicenseUrlRequest () {
  const res = await $get('/api/common/bizconfig/getMediaLicenseUrl')
  return res.data
}

export async function recordRequest (mediaid, b, t) {
  const res = await $post('/api/xapc/mediaLog', {
    mediaid,
    begin_datetime: b,
    times: t
  })
  return res.data
}

export async function addRequest (mediaid, content) {
  const res = await $post('/api/xapc/mediaComment', {
    mediaid,
    content
  })
  return res.data
}
