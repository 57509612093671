import React, { useState, useEffect, useMemo, useRef, useCallback, useReducer } from 'react'
import style from './Index.module.less'
import { Skeleton, Space, List, Input, Button, message, Result } from 'antd'
import { useParams } from 'react-router-dom'
import { getRequest, getLicenseUrlRequest, addRequest, recordRequest } from './ajax'
import { awaitWrap, confirmAction } from '@/assets/js/tool'
import TCPlayer from 'tcplayer.js'
import 'tcplayer.js/dist/tcplayer.min.css'
import Item from './components/Item/Index'
import classNames from 'classnames'

const { TextArea } = Input

let timer
const oMap = { beginTime: 0, currentTime: 0 }

async function init (id, setLoading, setData) {
  setLoading(true)
  const [e, d] = await awaitWrap(getRequest(id))
  setLoading(false)
  if (e === null && d !== null) {
    setData(d)
  }
}

async function initLicenseUrl (setUrl) {
  const [e, d] = await awaitWrap(getLicenseUrlRequest())
  if (e === null && d !== null) {
    setUrl(d)
  }
}

async function recordTime (mediaid) {
  const t = oMap.currentTime - oMap.beginTime
  if (!t) {
    return undefined
  }
  const [e] = await awaitWrap(recordRequest(mediaid, oMap.beginTime, t))
  if (e !== null) {
    console.error(e)
  }
}

async function recordOneTime (mediaid, time) {
  const [e] = await awaitWrap(recordRequest(mediaid, time, 1))
  if (e !== null) {
    console.error(e)
  }
}

async function submitHandle (id, t, callback) {
  if (!t.trim()) {
    message.warning('评论内容不能为空')
    return undefined
  }
  const h = message.loading('提交中')
  const [e] = await awaitWrap(addRequest(id, t))
  h()
  if (e === null) {
    message.success('提交成功', 1)
    callback()
  }
}

function reducer (state, action) {
  if (action.type === 'add') {
    return {
      time: state.time + 1
    }
  }
  if (action.type === 'clear') {
    return {
      time: 0
    }
  }
  throw Error('Unknown action.')
}

function Main ({ history }) {
  const videoRef = useRef()
  const { id, mediaid } = useParams()
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState(null)
  const [licenseUrl, setLicenseUrl] = useState('')
  const [player, setPlayer] = useState(null)
  const [value, setValue] = useState('')
  const [version, setVersion] = useState(Date.now())
  const [state, dispatch] = useReducer(reducer, { time: 0 })
  const loopTime = useCallback(() => {
    timer = setTimeout(() => {
      dispatch({ type: 'add' })
      loopTime()
    }, 1000)
  }, [dispatch])
  const media = useMemo(() => {
    if (data && mediaid) {
      const o = (data.medias || []).find(li => li.id === mediaid)
      if (o) {
        return o
      }
    }
    return null
  }, [data, mediaid])
  const list = useMemo(() => {
    if (data !== null && data.medias instanceof Array) {
      const o = data.medias.find(li => li.id === mediaid)
      if (o !== undefined && o.comments instanceof Array) {
        const l = o.comments
        l.sort((a, b) => b.create_datetime - a.create_datetime)
        return l
      }
    }
    return []
  }, [data, mediaid])
  const video = useMemo(() => {
    if (media && data && data.app_id && media.thirdid && media.psign) {
      return {
        fileID: media.thirdid,
        appID: data.app_id,
        psign: media.psign
      }
    }
  }, [media, data])
  const src = useMemo(() => {
    if (media !== null) {
      return media.media_url
    }
    return ''
  }, [media])
  const title = useMemo(() => {
    if (media) {
      return media.title
    }
    return ''
  }, [media])
  const isExpire = useMemo(() => {
    if (data && data.expiry_datetime) {
      return data.expiry_datetime * 1000 < Date.now()
    }
    return false
  }, [data])
  useEffect(() => {
    if (id) {
      init(id, setLoading, setData)
    }
  }, [id, version])
  useEffect(() => {
    initLicenseUrl(setLicenseUrl)
    return () => {
      timer && clearTimeout(timer)
    }
  }, [])
  useEffect(() => {
    if (licenseUrl !== '' && videoRef.current) {
      const o = TCPlayer('player-container-id', {
        sources: [],
        licenseUrl // license 地址，参考准备工作部分，在视立方控制台申请 license 后可获得 licenseUrl
      })
      setPlayer(o)
    }
  }, [licenseUrl])
  useEffect(() => {
    function playHandle () {
      const c = player.currentTime()
      oMap.beginTime = c
      oMap.currentTime = c
      loopTime()
      recordOneTime(mediaid, c)
    }
    function pauseHandle () {
      const c = player.currentTime()
      clearTimeout(timer)
      if (Math.abs(oMap.currentTime - c) > 1) {
        recordTime(mediaid).then(null, null)
        oMap.beginTime = c
      }
    }
    function timeupdateHandle () {
      const c = player.currentTime()
      if (Math.abs(oMap.currentTime - c) > 1) {
        clearTimeout(timer)
        recordTime(mediaid).then(null, null)
        oMap.beginTime = c
      }
      oMap.currentTime = c
    }
    function endedHandle () {
      recordTime(mediaid)
      clearTimeout(timer)
      oMap.currentTime = 0
      oMap.beginTime = 0
    }
    if (player) {
      player.on('play', playHandle)
      player.on('pause', pauseHandle)
      player.on('timeupdate', timeupdateHandle)
      player.on('ended', endedHandle)
      return () => {
        player.off('play', playHandle)
        player.off('pause', pauseHandle)
        player.off('timeupdate', timeupdateHandle)
        player.off('ended', endedHandle)
      }
    }
  }, [player, loopTime, mediaid])
  useEffect(() => {
    if (player !== null) {
      if (video) {
        player.loadVideoByID(video)
      } else if (src) {
        player.src(src)
      }
    }
  }, [src, player, video])
  useEffect(() => {
    if (player && media) {
      player.ready(() => {
        player.currentTime(media.end_datetime)
      })
    }
  }, [player, media])
  useEffect(() => {
    return () => {
      if (player) {
        player.dispose()
      }
    }
  }, [player])
  useEffect(() => {
    if (state.time >= 60) {
      recordTime(mediaid)
      dispatch({ type: 'clear' })
    }
  }, [mediaid, state])
  useEffect(() => {
    if (mediaid) {
      window.addEventListener('beforeunload', (event) => {
        if (oMap.currentTime - oMap.beginTime) {
          recordTime(mediaid)
          dispatch({ type: 'clear' })
        }
      })
    }
  }, [mediaid])
  useEffect(() => {
    return () => {
      if (mediaid) {
        recordTime(mediaid)
      }
    }
  }, [mediaid])
  if ((data && data.status !== '已发布') | isExpire) {
    return (
      <div className={style.container}>
        <Result
          status='warning'
          title='课程不存在或已下架'
          extra={
            <Button type='primary' onClick={() => history.goBack()}>返回</Button>
          }
        />
      </div>
    )
  }
  return (
    <div className={style.container}>
      <div className={style.inner}>
        <div className={style.skeleton}>
          <Skeleton active loading={loading} />
        </div>
        <div className={classNames({ [style.unvisible]: loading })}>
          <div className={style.title}>{title}</div>
          <div className={style.video} ref={videoRef}>
            <video id='player-container-id' preload='auto' width='718' height='404' {...{ playsInline: '', 'webkit-playsinline': '' }} />
          </div>
          <div className={style.label}>
            <Space>
              <span>评论</span>
              <span>{list.length}</span>
            </Space>
          </div>
          <div className={style.list}>
            <List dataSource={list} renderItem={(li, i) => <Item data={li} key={li.id} refreshFn={() => setVersion(Date.now())} />} />
          </div>
          <div className={style.footer}>
            <div className={style['footer-inner']}>
              <TextArea className={style.textarea} autoSize={false} placeholder='请输入' value={value} onInput={e => setValue(e.target.value)} maxLength={100} />
              <Button type='primary' className={style.submit} size='large' onClick={() => { confirmAction('您确定要提交评论吗？', () => { submitHandle(mediaid, value, () => { setVersion(Date.now()); setValue('') }) }) }}>提交</Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Main
