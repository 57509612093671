import React, { } from 'react'
import style from './Index.module.less'
import { download } from '@/assets/js/downloadRename'

function Main ({ data }) {
  if (!(data instanceof Array) || data.length === 0) {
    return <span />
  }
  return (
    <div className={style.container}>
      {
        data.map(li => {
          return (
            <div className={style.item} key={li.id}>
              <a className={style.link} onClick={() => download(li.path, li.original_filename)}>
                {li.original_filename}
              </a>
            </div>
          )
        })
      }
    </div>
  )
}

export default Main
