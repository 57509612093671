import React, { useEffect } from 'react'
import TCPlayer from 'tcplayer.js'
import 'tcplayer.js/dist/tcplayer.min.css'

function initSearch () {
  const search = location.search.replace('?', '')
  const l = search.split('&')
  const r = {}
  l.forEach(li => {
    const o = li.split('=')
    r[o[0]] = o[1]
  })
  return r
}

function Main () {
  useEffect(() => {
    const o = initSearch()
    if (o.src && o.licenseUrl) {
      TCPlayer('player-container-id', {
        sources: [{
          src: o.src // 播放地址
        }],
        licenseUrl: o.licenseUrl // license 地址，参考准备工作部分，在视立方控制台申请 license 后可获得 licenseUrl
      })
    }
  }, [])
  return (
    <div>
      <video id='player-container-id' preload='auto' width={document.body.clientWidth - 20} height={document.body.clientHeight - 20} {...{ playsInline: '', 'webkit-playsinline': '' }} />
    </div>
  )
}

export default Main
