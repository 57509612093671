import React from 'react'
import Component, { mapStateToProps, mapDispatchToProps } from '../components/Component'
import { connect } from 'react-redux'
import { ClockCircleOutlined, CaretRightOutlined } from '@ant-design/icons'
import style from './CaseList.module.less'
import { message, List, Radio } from 'antd'
import moment from 'moment'

class CaseList extends Component {
  state = {
    isAsc: true,
    list: [],
    loading: false,
    status: '审理中'
  }

  getList () {
    const doing = ['移交办案', '已组庭', '已结案']
    const closed = ['已结案送达', '已归档']
    const condition = []
    const status = this.state.status
    if (status === '审理中') {
      condition.push({ k: 'status', o: 'in', v: doing.join() })
    } else if (status === '已结案') {
      condition.push({ k: 'status', o: 'in', v: closed.join() })
    }
    this.setState({ loading: true })
    this.$get('/api/arbitrator/case', {
      params: {
        current: 1,
        page_size: 1000,
        condition: JSON.stringify(condition)
      }
    }).then(res => {
      this.setState({ loading: false })
      if (res.status === 1) {
        let list = res.data.list
        list = list.map((li, i) => {
          li.order = i
          return li
        })
        this.setState({
          list
        })
      } else {
        message.warning(res.message)
      }
    }).catch(() => this.setState({ loading: false }))
  }

  // 前往详情
  goToDetail (id, status) {
    if (status) {
      this.props.history.push(`/admin/detail/${id}`)
    }
  }

  renderCnNum (num = 0) {
    return ['一', '二', '三'][num]
  }

  formatDate (d) {
    if (d) {
      return moment(d).format('YYYY-MM-DD')
    }
  }

  formatFullDate (d) {
    if (d) {
      return moment(d).format('YYYY-MM-DD HH:mm')
    }
  }

  // 修改筛选状态
  changeStatus (s) {
    this.setState({
      status: s
    }, () => {
      this.getList()
    })
  }

  componentDidMount () {
    this.getList()
  }

  // 渲染秘书信息
  renderScretary (d) {
    if (!d.processer) {
      return null
    }
    return (
      <div className={style['li-other-line']}>
        <span className={style['li-other-line-cell']}>办案秘书：{d.processer}</span>
        <span className={style['li-other-line-cell']}>电话：{d.processer_tel || '无'}</span>
        <span className={style['li-other-line-cell']}>邮箱：{d.processer_email || '无'}</span>
      </div>
    )
  }

  // 渲染仲裁员信息
  renderArbitrator (d) {
    if (!d.arbitratorids) {
      return null
    }
    return <div className={style['li-other-line']}>仲裁员：{d.arbitrators}</div>
  }

  // 渲染开庭信息
  renderCourt (d) {
    if (!d.hearing_datetime) {
      return null
    }
    return (
      <div className={style['li-other-line']}>开庭日期：{this.formatFullDate(d.hearing_datetime * 1000)}</div>
    )
  }

  // 渲染其他信息
  renderOtherInfo (d) {
    if (!d.processer && !d.arbitratorids && !d.hearing_datetime) {
      return null
    }
    return (
      <div className={style['li-other']}>
        {this.renderScretary(d)}
        {this.renderArbitrator(d)}
        {this.renderCourt(d)}
      </div>
    )
  }

  renderItem (d) {
    const statusClass = [style['li-status']]
    let status = d.status
    const auditList = ['已提交', '待审核']
    const sendingStatus = ['交费', '立案', '移交送达', '送达完成']
    const doing = ['移交办案', '已组庭', '已结案']
    const close = ['已结案送达', '已归档']
    if (auditList.indexOf(status) !== -1) {
      statusClass.push(style.pass)
      status = '待审核'
    } else if (status === '收案') {
      statusClass.push(style.wait)
      status = '已受理'
    } else if (sendingStatus.indexOf(status) !== -1) {
      statusClass.push(style.wait)
      status = '送达中'
    } else if (doing.indexOf(status) !== -1) {
      statusClass.push(style.wait)
      status = '审理中'
    } else if (close.indexOf(status) !== -1) {
      statusClass.push(style.close)
      status = '已结案'
    } else if (status === '不予受理') {
      statusClass.push(style.close)
      status = '不予受理'
    } else {
      statusClass.push(style.unpass)
      status = '未提交'
    }
    return (
      <div className={style.li} key={d.id}>
        <div className={style['li-no']}>{d.order + 1}</div>
        <div className={style['li-header']} onClick={() => this.goToDetail(d.id, d.status)}>
          <div className={style['li-header__left']}>
            <span className={style['li-caseno']}>案件编号 {d.caseno_desc}</span>
            <span className={statusClass.join(' ')}>
              <ClockCircleOutlined className={style['li-status-icon']} />
              <span>{status}</span>
            </span>
          </div>
          <div className={style['li-header__right']}>
            <CaretRightOutlined />
          </div>
        </div>
        {this.renderOtherInfo(d)}
        <div className={style['li-body']}>
          <div className={style['li-body__left']}>
            <div className={style['li-persno-list']}>
              <div className={style['li-person__title']}>
                <img className={style['li-person-icon']} src={require('../assets/img/case-person-icon.png')} />
                <span>申请人</span>
              </div>
              <div className={style['li-person-names']}>
                {d.applicants.map((li, i) => <span key={i} title={li.name}>{this.renderCnNum(i)}、{li.name}</span>)}
              </div>
            </div>
            <div className={style['li-persno-list']}>
              <div className={style['li-person__title']}>
                <img className={style['li-person-icon']} src={require('../assets/img/case-person-icon.png')} />
                <span>被申请人</span>
              </div>
              <div className={style['li-person-names']}>
                {d.respondents.map((li, i) => <span key={li.id} title={li.name}>{this.renderCnNum(i)}、{li.name}</span>)}
              </div>
            </div>
          </div>
          {d.submit_datetime ? (
            <div className={style['li-body__right']}>
              <span>申请时间</span>
              <span>{this.formatDate(d.submit_datetime * 1000)}</span>
            </div>
          ) : null}
        </div>
      </div>
    )
  }

  renderList () {
    const orderClass = [style['order-btn']]
    if (this.state.isAsc) {
      orderClass.push(style.asc)
    }
    return (
      <div className={style.container}>
        <div className={style.header}>
          <span className={style['header-left']}>
            <span className={style['header-title']}>我的案件</span>
            <span className={style['header-sub-title']}>{this.state.list.length}</span>
          </span>
          <span className={style['header-right']}>
            <Radio.Group value={this.state.status} onChange={v => this.changeStatus(v.target.value)}>
              <Radio.Button value='全部'>全部</Radio.Button>
              <Radio.Button value='审理中'>审理中</Radio.Button>
              <Radio.Button value='已结案'>已结案</Radio.Button>
            </Radio.Group>
          </span>
          {/* <span className={style['header-right']}>
            <span className={style['search-input']}>
              <input type='text' placeholder='搜索姓名' />
              <span className={style['search-btn']}>
                <SearchOutlined />
              </span>
            </span>
            <span className={orderClass.join(' ')} onClick={() => this.setState({ isAsc: !this.state.isAsc })}>
              <span />
              <span />
              <span />
            </span>
          </span> */}
        </div>
        <div className={style.list}>
          <List dataSource={this.state.list} loading={this.state.loading} renderItem={d => this.renderItem(d)} />
        </div>
      </div>
    )
  }

  render () {
    return (
      <div>
        {this.renderList()}
      </div>
    )
  }
}
const mapState = mapStateToProps(function (state) {
  return {
    userInfo: state.basic.userInfo
  }
})

const mapDispatch = mapDispatchToProps(function (dispatch) {
  return {
  }
})
export default connect(mapState, mapDispatch)(CaseList)
