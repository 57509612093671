import React from 'react'
import Component, { mapStateToProps, mapDispatchToProps } from '../components/Component'
import { connect } from 'react-redux'
import { Form, Input, message, Button } from 'antd'
import Text from '../components/Text'
import style from './UserInfo.module.less'
import { compress, decompress } from '@/assets/js/tool'
const { Item } = Form
const { TextArea } = Input
class UserInfo extends Component {
  state = {
    majors: []
  }

  formRef = React.createRef()

  // 初始化数据
  initData () {
    const hide = message.loading('获取数据中', 120)
    this.$get(`/api/arbitrator/me/${this.props.arbitratorInfo.id}`)
      .then(res => {
        hide()
        if (res.status === 1) {
          const d = res.data
          if (typeof d.mobile === 'string' && d.mobile !== '') {
            d.mobile = decompress(d.mobile)
          }
          if (typeof d.license === 'string' && d.license !== '') {
            d.license = decompress(d.license)
          }
          if (typeof d.bank_accountno === 'string' && d.bank_accountno !== '') {
            d.bank_accountno = decompress(d.bank_accountno)
          }
          this.formRef.current.setFieldsValue(d)
        } else {
          message.warning(res.message)
        }
      }).catch(() => hide())
  }

  onFinish (d) {
    const hide = message.loading('更新中', 120)
    if (typeof d.license === 'string' && d.license !== '') {
      d.license = compress(d.license)
    }
    if (typeof d.bank_accountno === 'string' && d.bank_accountno !== '') {
      d.bank_accountno = compress(d.bank_accountno)
    }
    delete d.mobile
    this.$put(`/api/arbitrator/me/${this.props.arbitratorInfo.accountid}`, d)
      .then(res => {
        hide()
        if (res.status === 1) {
          message.success('更新成功')
        } else {
          message.warning(res.message)
        }
      }).catch(() => hide())
  }

  componentDidMount () {
    this.initData()
  }

  render () {
    return (
      <div className={style.container}>
        <div className={style.header}>个人信息维护</div>
        <div className={style.body}>
          <Form ref={this.formRef} className={style.form} labelCol={{ flex: '128px' }} labelAlign='left' colon={false} onFinish={d => this.onFinish(d)}>
            <Item name='mobile' label='手机号码' required>
              <Text />
            </Item>
            <Item name='license' label='身份证号'>
              <Input className={style['long-input']} placeholder='请输入身份证号' maxLength='18' />
            </Item>
            <Item name='tel' label='固定电话' rules={[{ required: true, message: '固定电话不能为空' }]}>
              <Input className={style['long-input']} placeholder='请输入固定电话' />
            </Item>
            <Item name='structure_address' label='收件地址' rules={[{ required: true, message: '收件地址不能为空' }]}>
              <TextArea className={style['long-input']} placeholder='请输入收件地址' rows={3} />
            </Item>
            <Item name='structure' label='所在单位' rules={[{ required: true, message: '所在单位不能为空' }]}>
              <Input className={style['long-input']} placeholder='退休人员请填写退休' />
            </Item>
            <Item name='structure_tel' label='单位电话'>
              <Input className={style['long-input']} placeholder='请输入单位电话' />
            </Item>
            <Item name='bank' label='开户行'>
              <Input className={style['long-input']} placeholder='请输入开户行' />
            </Item>
            <Item name='bank_accountno' label='银行卡号'>
              <Input className={style['long-input']} placeholder='优先填写工行号码' />
            </Item>
            <div className={style['btn-line']}>
              <Button type='primary' htmlType='submit'>确定</Button>
            </div>
          </Form>
        </div>
      </div>
    )
  }
}

const mapState = mapStateToProps(function (state) {
  return {
    userInfo: state.basic.userInfo,
    arbitratorInfo: state.basic.arbitratorInfo
  }
})

const mapDispatch = mapDispatchToProps(function (dispatch) {
  return {

  }
})
export default connect(mapState, mapDispatch)(UserInfo)
