import { $get } from '@/assets/js/request'
import { message } from 'antd'

// 获取案由树
export async function getTreeData () {
  const res = await $get('/api/common/cause_action')
  if (res.status !== 1) {
    throw new Error(res.message)
  }
  return res.data
}

export async function getAreasRequest () {
  const res = await $get('/api/arbitrator//arbitrator/area')
  if (res.status !== 1) {
    message.error(res.message)
    throw new Error(res.message)
  }
  return res.data
}

export async function getListRequest (text) {
  const condition = []
  if (text) {
    condition.push({ k: 'title', o: 'like', v: `%${text}%` })
  }
  const res = await $get('/api/arbitrator//researchtag', {
    params: {
      condition: JSON.stringify(condition)
    }
  })
  if (res.status !== 1) {
    message.error(res.message)
    throw new Error(res.message)
  }
  return res.data
}

export async function getTreeRequest () {
  const res = await $get('/api/arbitrator//professionaltag')
  if (res.status !== 1) {
    message.error(res.message)
    throw new Error(res.message)
  }
  return res.data
}
