import { $post } from '@/assets/js/authRequest'

export async function loginRequest (username, password, verification) {
  const res = await $post('/api/common/vuser_login', {
    verify_plus: 'sms',
    verification,
    username,
    password
  })
  if (res.status !== 1) {
    throw new Error(res.message)
  }
  return res.data
}
