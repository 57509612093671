import React, { useMemo } from 'react'
import { DeleteOutlined } from '@ant-design/icons'
// import moment from 'moment'
import style from './Index.module.less'
import { Button, Space, message } from 'antd'
import { awaitWrap, confirmAction, formatDate } from '@/assets/js/tool'
import classNames from 'classnames'
import { useSelector } from 'react-redux'
import { delRequest } from './ajax'
// import { Button, message } from 'antd'

async function delHandle (id, callback) {
  const h = message.loading('删除中', 120)
  const [e] = await awaitWrap(delRequest(id))
  h()
  if (e === null) {
    message.success('删除成功', 1)
    callback()
  }
}

function Main ({ data: d, refreshFn }) {
  const userInfo = useSelector(state => state.basic.userInfo)
  const isMe = useMemo(() => {
    return d.operatorid === userInfo.id
  }, [d, userInfo])
  return (
    <div className={style.container} key={d.id}>
      <div className={style.header}>
        <Space>
          <span className={classNames(style.name, { [style.active]: isMe })}>{isMe ? '我' : d.operator}</span>
          <span className={style.time}>{formatDate(d.create_datetime, 'YYYY-MM-DD')}</span>
        </Space>
        {isMe && <Button type='link' icon={<DeleteOutlined />} className={style.del} onClick={() => confirmAction('您确定要删除该评论吗？', () => { delHandle(d.id, refreshFn) })} />}
      </div>
      <div className={style.content}>
        {d.content}
      </div>
    </div>
  )
}

export default Main
