import React, { } from 'react'
// import { ClockCircleOutlined } from '@ant-design/icons'
// import moment from 'moment'
import style from './Index.module.less'
// import { Button, message } from 'antd'

function Main ({ data: d, history }) {
  return (
    <div className={style.container} key={d.id} onClick={() => { history.push(`/admin/course/${d.id}`) }}>
      <div className={style.img}>
        {d.cover_url && <img src={d.cover_url} />}
      </div>
      <div className={style.title}>{d.title}</div>
      <div className={style.speaker}>主讲人：{d.speaker}</div>
    </div>
  )
}

export default Main
