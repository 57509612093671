import React from 'react'
import { Modal, Form, Input, Row, Col, Button, message, Alert } from 'antd'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons'
import Component, { mapStateToProps, mapDispatchToProps } from '@/components/Component'
import { setSendTime, setToken, setUserInfo } from '@/redux/actions'
import moment from 'moment'
import style from './Index.module.less'
import { compress } from '@/assets/js/tool'
import SendSmsValidateModal from '@/components/SendSmsValidateModal/Index'
const { Item } = Form
class LogoutModal extends Component {
  state = {
    passwordHidable: true,
    loopTime: 0, // 倒计时
    smsValidateVisible: false
  }

  formRef=React.createRef()

  // 设置倒计时
  setLoopTime () {
    const { sendTime } = this.props
    if (!sendTime) {
      return false
    }
    const time = 60 - Math.ceil((moment() - sendTime) / 1000)
    this.setState({
      loopTime: time > 0 ? time : 0
    })
  }

  // 倒计时
  loop () {
    this.setLoopTime()
    if (this.state.loopTime > 0) {
      this.timer = setTimeout(() => {
        this.loop()
      }, 1000)
    }
  }

  // 发送验证码
  sendSMS () {
    if (!this.props.userInfo.mobile) {
      return message.warning('手机号码不存在')
    }
    this.setState({ smsValidateVisible: true })
  }

  // 确定提交
  submit (d) {
    const hide = message.loading('提交中', 120)
    this.$post('/api/common/edit_password', {
      mobile: compress(this.props.userInfo.mobile),
      ...d
    })
      .then(res => {
        hide()
        if (res.status !== 1) {
          return message.warning(res.message)
        }
        this.props.hide()
        message.success(res.message, 2, () => { })
        this.props.setToken('')
        this.props.setUserInfo({})
        this.props.history.replace('/login')
      })
      .catch(() => hide())
  }

  validator (rule, value) {
    if (!value) {
      return Promise.resolve()
    }
    if (value.length < 8) {
      return Promise.reject(new Error('最小长度为8位'))
    }
    let count = 0
    if (/[$-]+/.test(value)) {
      return Promise.reject(new Error('新密码不能包含-和$'))
    }
    if (/[a-z]+/.test(value) && /[A-Z]+/.test(value)) {
      count++
    }
    if (/[~!@#%^&*()_+=*/<>?/\\.,;'"]+/.test(value)) {
      count++
    }
    if (/[0-9]+/.test(value)) {
      count++
    }
    if (count < 3) {
      return Promise.reject(new Error('新密码必须包含大小写字母+数字+特殊符号'))
    }
    return Promise.resolve()
  }

  afterSendSms () {
    this.setState({ smsValidateVisible: false })
    setTimeout(() => {
      this.loop()
    }, 120)
  }

  componentDidMount () {
    this.setLoopTime()
    setTimeout(() => {
      this.loop()
    }, 120)
  }

  componentWillUnmount () {
    this.timer && clearTimeout(this.timer)
  }

  // 渲染发送短信按钮
  renderSendBtn () {
    const { loopTime } = this.state
    if (loopTime) {
      return (
        <Button disabled>{loopTime}秒</Button>
      )
    } else {
      return (
        <Button type='primary' onClick={() => this.sendSMS()} ghost>发送验证码</Button>
      )
    }
  }

  renderEye () {
    return (
      <span className={style['eye-btn']} onClick={() => this.setState({ passwordHidable: !this.state.passwordHidable })}>
        {this.state.passwordHidable ? <EyeInvisibleOutlined /> : <EyeOutlined />}
      </span>
    )
  }

  render () {
    return (
      <Modal
        title='修改密码'
        visible={this.props.visible}
        onOk={() => this.formRef.current.submit()}
        onCancel={() => this.props.hide()}
        maskClosable={false}
        destroyOnClose
        closable={!this.props.isExpire}
      >
        <SendSmsValidateModal visible={this.state.smsValidateVisible} hide={() => this.setState({ smsValidateVisible: false })} mobile={this.props.userInfo.mobile} callback={() => { this.afterSendSms() }} />
        <Form name='new-password' labelCol={{ flex: '88px' }} ref={this.formRef} onFinish={d => this.submit(d)}>
          {this.props.expireInfo && <Alert message={this.props.expireInfo} style={{ marginBottom: '12px' }} type='warning' />}
          <Item label='手机号'>
            <span>{this.props.userInfo.mobile || '无'}</span>
          </Item>
          <Item name='new_password' label='新密码' rules={[{ required: true, message: '新密码不能为空' }, { validator: this.validator }]}>
            <Input type={this.state.passwordHidable ? 'password' : 'text'} placeholder='请输入新密码' suffix={this.renderEye()} allowClear />
          </Item>
          <Row>
            <Col flex={1}>
              <Item name='verification' label='验证码' rules={[{ required: true, message: '验证码不能为空' }]}>
                <Input maxLength={4} placeholder='请输入验证码' allowClear />
              </Item>
            </Col>
            <Col offset={1}>
              {this.renderSendBtn()}
            </Col>
          </Row>
        </Form>
      </Modal>
    )
  }
}

LogoutModal.propTypes = {
  visible: PropTypes.bool,
  hide: PropTypes.func,
  isExpire: PropTypes.bool,
  expireInfo: PropTypes.string
}

LogoutModal.defaultProps = {
  visible: false,
  hide: () => {},
  isExpire: false,
  expireInfo: ''
}

const mapState = mapStateToProps(function (state) {
  return {
    sendTime: state.basic.sendTime
  }
})

const mapDispatch = mapDispatchToProps(function (dispatch) {
  return {
    setSendTime: (i) => dispatch(setSendTime(i)),
    setToken: i => dispatch(setToken(i)),
    setUserInfo: i => dispatch(setUserInfo(i))
  }
})

export default connect(mapState, mapDispatch)(LogoutModal)
