import React from 'react'
import Component, { mapStateToProps, mapDispatchToProps } from '../components/Component'
import { connect } from 'react-redux'
import style from './Detail.module.less'
import { message, Skeleton } from 'antd'
import InputModal from '../components/ApplyInput/Modal'
import { formatCaseDataLicenseAndMobile, formatDate } from '@/assets/js/tool'
import FileList from '@/components/FileList/Index'

class Detail extends Component {
  state = {
    detailData: {},
    applicationEvidences: [],
    respondentEvidences: []
  }

  getDetail () {
    const hide = message.loading('获取详情数据')
    this.$get(`/api/arbitrator/case/${this.props.match.params.id}`)
      .then(res => {
        hide()
        if (res.status === 1) {
          if (res.data !== null) {
            formatCaseDataLicenseAndMobile(res.data)
          }
          this.setState({
            detailData: res.data,
            applicationEvidences: res.data.evidence.filter(li => ['申请人', '申请人代理人'].includes(li.submittal_type)),
            respondentEvidences: res.data.evidence.filter(li => ['被申请人', '被申请人代理人'].includes(li.submittal_type))
          })
        } else {
          message.warning(res.message)
        }
      })
      .catch(() => hide())
  }

  getCnNum (n) {
    const num = String(n)
    const units = ['一', '二', '三', '四', '五', '六', '七', '八', '九']
    const unit = '十'
    if (num.length === 2) {
      const ten = parseInt(num[0])
      const n = parseInt(num[1])
      if (ten === 1) {
        return unit + (n === 0 ? '' : units[n - 1])
      }
      return units[ten - 1] + unit + (n === 0 ? '' : units[n - 1])
    }
    return units[n - 1]
  }

  // 获取当事人名称
  getAgentPartyNames (d) {
    const map = {}
    const list = []
    this.state.detailData.applicants.forEach(li => {
      map[li.id] = li.name
    })
    d.party.forEach(c => {
      list.push(map[c.partyid])
    })
    return list.join('、')
  }

  // 格式化金钱
  formatMoney (d) {
    if (!d) {
      return '无'
    }
    return `${d} 元`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }

  componentDidMount () {
    this.getDetail()
  }

  // 选择证件照
  renderPics (li) {
    const file = li.file || []
    let front = null
    let back = null
    if (file.length) {
      front = file[0]
      back = file[1]
    }
    return (
      <div className={style['identity-block']}>
        {front ? (
          <a className={style['identity-cell']} href={front.url} target='blank'>
            <img className={style['identity-pic']} src={front.url} />
            <div className={style['identity-title']}>人像照</div>
          </a>
        ) : null}
        {back ? (
          <a className={style['identity-cell']} href={back.url} target='blank'>
            <img className={style['identity-pic']} src={back.url} />
            <div className={style['identity-title']}>国徽照</div>
          </a>
        ) : null}
      </div>
    )
  }

  renderParty (list) {
    return list.map((li, i) => {
      return (
        <div className={style.block} key={li.id} style={{ width: '450px' }}>
          <div className={style['block-number']}>{this.getCnNum(i + 1)}</div>
          <div className={style.list}>
            <div className={style.li}>
              <span className={style['li-title']}>姓名</span>
              <span className={style['li-text']}>{li.name || '无'}</span>
            </div>
            <div className={style.li}>
              <span className={style['li-title']}>证件类型</span>
              <span className={style['li-text']}>{li.license_type || '无'}</span>
            </div>
            <div className={style.li}>
              <span className={style['li-title']}>证件号码</span>
              <span className={style['li-text']}>{li.license || '无'}</span>
            </div>
            <div className={style.li}>
              <span className={style['li-title']}>手机号码</span>
              <span className={style['li-text']}>{li.mobile || '无'}</span>
            </div>
            <div className={style.li}>
              <span className={style['li-title']}>电子邮箱</span>
              <span className={style['li-text']}>{li.email || '无'}</span>
            </div>
            <div className={style.li}>
              <span className={style['li-title']}>固定电话</span>
              <span className={style['li-text']}>{li.tel || '无'}</span>
            </div>
          </div>
          {this.renderPics(li)}
        </div>
      )
    })
  }

  renderAgent () {
    if (this.state.detailData.applicant_agents.length === 0) {
      return null
    }
    return (
      <>
        <div className={style['title-line']}>
          <img className={style['title-icon']} src={require('../assets/img/case-person-icon.png')} />
          <span className={style['title-text']}>代理人</span>
        </div>
        {this.renderAgentList()}
      </>
    )
  }

  renderAgentList () {
    return this.state.detailData.applicant_agents.map((li, i) => {
      return (
        <div className={style.block} key={li.id} style={{ width: '450px' }}>
          <div className={style['block-number']}>{this.getCnNum(i + 1)}</div>
          <div className={style.list}>
            <div className={style.li}>
              <span className={style['li-title']}>代理对象</span>
              <span className={style['li-text']}>{this.getAgentPartyNames(li)}</span>
            </div>
            <div className={style.li}>
              <span className={style['li-title']}>姓名</span>
              <span className={style['li-text']}>{li.name || '无'}</span>
            </div>
            <div className={style.li}>
              <span className={style['li-title']}>证件类型</span>
              <span className={style['li-text']}>{li.license_type || '无'}</span>
            </div>
            <div className={style.li}>
              <span className={style['li-title']}>证件号码</span>
              <span className={style['li-text']}>{li.license || '无'}</span>
            </div>
            <div className={style.li}>
              <span className={style['li-title']}>所在单位</span>
              <span className={style['li-text']}>{li.structure || '无'}</span>
            </div>
            <div className={style.li}>
              <span className={style['li-title']}>手机号码</span>
              <span className={style['li-text']}>{li.mobile || '无'}</span>
            </div>
            <div className={style.li}>
              <span className={style['li-title']}>电子邮箱</span>
              <span className={style['li-text']}>{li.email || '无'}</span>
            </div>
            <div className={style.li}>
              <span className={style['li-title']}>固定电话</span>
              <span className={style['li-text']}>{li.tel || '无'}</span>
            </div>
          </div>
          {this.renderPics(li)}
        </div>
      )
    })
  }

  renderEvidence () {
    const { applicationEvidences: a, respondentEvidences: b } = this.state
    return (
      <div>
        {
          a.length > 0 && (
            <>
              <div className={style['evidence-title']}>
                <span>申请人</span>
              </div>
              {a.map((li, i) => this.renderEvidenceItem(li, i))}
            </>
          )
        }
        {
          b.length > 0 && (
            <>
              <div className={style['evidence-title']}>
                <span>被申请人</span>
              </div>
            </>
          )
        }
      </div>
    )
  }

  renderEvidenceItem (data, i) {
    return (
      <div className={style['evidence-item']} key={data.id}>
        <span className={style.order}>{i + 1}</span>
        <div className={style['evidence-item-content']}>
          <div className={style['evidence-item-title']}>
            <span>{data.submittal} - {data.name}</span>
            <span className={style['evidence-time']}>{formatDate(data.create_datetime)}</span>
          </div>
          <div>{data.proof}</div>
          <div>
            <FileList data={data.file} />
          </div>
        </div>
      </div>
    )
  }

  render () {
    const d = this.state.detailData
    let claim = []
    let reason = ''
    if (d.claim) {
      claim = d.claim.items
      reason = d.claim.statement
    }
    if (!d.id) {
      return (
        <InputModal title='详情' back={() => this.props.history.back()}>
          <div style={{ padding: '24px' }}>
            <Skeleton />
          </div>
        </InputModal>
      )
    }
    return (
      <InputModal title='详情' back={() => this.props.history.goBack()}>
        <div className={style.container}>
          <div className={style.header}>
            <img className={style['header-icon']} src={require('../assets/img/logo.png')} />
            <span className={style['header-title']}>案件详情</span>
          </div>

          <div className={style['title-line']}>
            <img className={style['title-icon']} src={require('../assets/img/case-person-icon.png')} />
            <span className={style['title-text']}>申请人</span>
          </div>
          {this.renderParty(this.state.detailData.applicants)}

          {this.renderAgent()}

          <div className={style['title-line']}>
            <img className={style['title-icon']} src={require('../assets/img/case-person-icon.png')} />
            <span className={style['title-text']}>被申请人</span>
          </div>
          {this.renderParty(this.state.detailData.respondents)}

          <div className={style['title-line']}>
            <img className={style['title-icon']} src={require('../assets/img/sms-icon.png')} />
            <span className={style['title-text']}>仲裁请求</span>
          </div>
          <div className={style.block}>
            <div className={style.list}>
              {
                (claim || []).map((li, i) => {
                  return (
                    <div className={style['claim-li']} key={li.id}>
                      <span className={style['claim-title']}>
                        <span className={style.order}>{i + 1}</span>
                        <span className={style['claim-desc']}>{li.desc}</span>
                      </span>
                      <span className={style['claim-value']}>{this.formatMoney(li.dispute_amount)}</span>
                    </div>
                  )
                })
              }
              <div className={[style.li, style.reason].join(' ')}>
                <span className={style['li-title']}>事实与理由</span>
                <span className={style['li-text']}>{reason || '无'}</span>
              </div>
            </div>
          </div>

          {this.state.detailData.evidence.length ? (
            <div className={style['title-line']}>
              <img className={style['title-icon']} src={require('../assets/img/file-icon.png')} />
              <span className={style['title-text']}>证据材料</span>
            </div>
          ) : null}
          {this.renderEvidence()}

          {/* <div className={style['bottom-btn-line']}>
            <Space size={16}>
              <Button className={style['normal-btn']}>返回编辑</Button>
              <Button type='primary'>确认提交</Button>
            </Space>
          </div> */}
          <div style={{ paddingBottom: '24px' }} />
        </div>
      </InputModal>
    )
  }
}

const mapState = mapStateToProps(function (state) {
  return {
    userInfo: state.basic.userInfo
  }
})

const mapDispatch = mapDispatchToProps(function (dispatch) {
  return {

  }
})
export default connect(mapState, mapDispatch)(Detail)
