import { $get } from './request'
import { awaitWrap } from './tool'
import { message } from 'antd'

// 获取字典请求
async function getDictionaryRequest (types) {
  const res = await $get('/api/arbitrator/dictionary', {
    params: {
      types
    }
  })
  if (res.status !== 1) {
    throw new Error(res.message)
  }
  return formatDictionaries(res.data)
}

// 获取字典
async function getDictionary (types, setDictionary) {
  const [e, d] = await awaitWrap(getDictionaryRequest(types))
  if (e !== null) {
    return undefined
  }
  if (d === null) {
    return await message.error('字典数据未返回')
  }
  setDictionary(d)
}

// 格式化枚举字典
function formatDictionaries (dictionaries = []) {
  const result = {}
  dictionaries.forEach(d => {
    result[d.type] = d.list
  })
  return result
}

export default class Dictionary {
  store = {}
  async init (types) {
    await getDictionary(types, this.setStore.bind(this))
  }

  setStore (store) {
    this.store = store
  }

  getStore () {
    return this.store
  }

  getList (type) {
    if (Object.prototype.hasOwnProperty.call(this.store, type)) {
      return this.store[type]
    }
    return []
  }
}
