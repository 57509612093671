import React, { useState, useEffect, useMemo } from 'react'
import style from './Index.module.less'
import { Button, Result, Skeleton } from 'antd'
import { useParams, Link } from 'react-router-dom'
import { getRequest } from './ajax'
import { awaitWrap } from '@/assets/js/tool'
import { download } from '@/assets/js/downloadRename'

async function init (id, setLoading, setData) {
  setLoading(true)
  const [e, d] = await awaitWrap(getRequest(id))
  setLoading(false)
  if (e === null && d !== null) {
    setData(d)
  }
}

function Main ({ history }) {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState(null)
  const { id } = useParams()
  const files = useMemo(() => {
    if (data && data.file instanceof Array) {
      return data.file
    }
    return []
  }, [data])
  const isExpire = useMemo(() => {
    if (data && data.expiry_datetime) {
      return data.expiry_datetime * 1000 < Date.now()
    }
    return false
  }, [data])
  useEffect(() => {
    if (id) {
      init(id, setLoading, setData)
    }
  }, [id])
  if ((data && data.status !== '已发布') || isExpire) {
    return (
      <div className={style.container}>
        <Result
          status='warning'
          title='课程不存在或已下架'
          extra={
            <Button type='primary' onClick={() => history.goBack()}>返回</Button>
          }
        />
      </div>
    )
  }
  return (
    <div className={style.container}>
      {!data && <Skeleton active loading={loading} />}
      {
        data && (
          <div className={style.inner}>
            <div className={style.title}>课程详情</div>
            {data.cover_url && (
              <div className={style.img}>
                <img src={data.cover_url} />
              </div>
            )}
            <div className={style['course-title']}>{data.title}</div>
            <div className={style.label}>主讲人</div>
            <div className={style.value}>{data.speaker}</div>
            <div className={style.label}>课程简介</div>
            <div className={style.value}>{data.info}</div>
            {
              files.length > 0 && (
                <>
                  <div className={style.label}>课程讲义</div>
                  {
                    files.map((li, i) => {
                      return (
                        <div className={style.media} key={i}>
                          <span className={style['media-no']}>{i + 1}</span>
                          <a onClick={() => download(li.path, li.original_filename)}>{li.original_filename}</a>
                        </div>
                      )
                    })
                  }
                </>
              )
            }
            <div className={style.label}>课程列表</div>
            <div className={style.medias}>
              {
                (data.medias || []).map((li, i) => {
                  return (
                    <div className={style.media} key={i}>
                      <span className={style['media-no']}>{i + 1}</span>
                      <Link to={`/admin/course/${id}/${li.id}`}>{li.title}</Link>
                    </div>
                  )
                })
              }
            </div>
          </div>
        )
      }
    </div>
  )
}

export default Main
