import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import 'core-js/es'
import 'mutation-observer'
import React from 'react'
import ReactDOM from 'react-dom'
import moment from 'moment'
import Routers from './router/Routers'
import * as serviceWorker from './serviceWorker'
import { Provider } from 'react-redux'
import store, { persistor } from './redux/store'
import { PersistGate } from 'redux-persist/integration/react'
import { ConfigProvider } from 'antd'
import zhCN from 'antd/lib/locale/zh_CN'
import 'moment/locale/zh-cn'
import './index.less'
moment.locale('zh-cn')

ReactDOM.render(
  <ConfigProvider locale={zhCN}>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        {/* <React.StrictMode> */}
        <Routers />
        {/* </React.StrictMode> */}
      </PersistGate>
    </Provider>
  </ConfigProvider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
