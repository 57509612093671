import React from 'react'
import Component, { mapStateToProps, mapDispatchToProps } from '../components/Component'
import { connect } from 'react-redux'
import { Avatar, Menu, Dropdown, Space, message } from 'antd'
import { UserOutlined, PoweroffOutlined } from '@ant-design/icons'
import style from './Admin.module.less'
import { companyName } from '../assets/js/config'
import { Switch, Route, Redirect, NavLink } from 'react-router-dom'
import CaseList from '../pages/CaseList'
import Detail from '../pages/Detail.js'
import UserInfo from '../pages/UserInfo.js'
import { setUserInfo, setToken, setSendTime } from '../redux/actions'
import OpenList from '../pages/OpenList'
import ModifyPasswordModal from '../components/ModifyPasswordModal/Index'
import MeetingList from '../pages/MeetingList/Index'
import CourseList from '../pages/CourseList/Index'
import CourseDetail from '../pages/CourseDetail/Index'
import CourseDetailVideo from '../pages/CourseDetailVideo/Index'

class Admin extends Component {
  state = {
    modifyVisible: false,
    isExpire: false,
    expireInfo: ''
  }

  // 退出登录
  logout () {
    this.$post('/api/common/logout')
      .then(res => {
        if (res.status === 1) {
          message.success('退出成功！', 2, () => {
            this.props.setToken('')
            this.props.setUserInfo({})
            this.props.setSendTime()
            this.props.history.push('/login')
          })
        } else {
          message.error(res.message, 2)
        }
      })
      .catch(() => {
        message.error('退出登录失败！', 2)
      })
  }

  renderMenu () {
    return (
      <Menu items={[
        { label: '修改密码', onClick: () => { this.setState({ modifyVisible: true }) } },
        { label: '退出', icon: <PoweroffOutlined className={style.error} />, onClick: () => this.logout() }
      ]}
      />
    )
  }

  initExpire () {
    const d = this.props.userInfo
    const isExpire = d.is_expire === 1
    this.setState({
      expireInfo: d.expire_info,
      isExpire,
      modifyVisible: isExpire
    })
  }

  componentDidMount () {
    this.initExpire()
  }

  render () {
    const userInfo = this.props.userInfo || {}
    return (
      <div className={style.container}>
        <ModifyPasswordModal
          visible={this.state.modifyVisible} hide={() => {
            this.setState({ modifyVisible: false })
            if (this.state.isExpire) {
              this.logout()
            }
          }} history={this.props.history} isExpire={this.state.isExpire} expireInfo={this.state.expireInfo}
        />
        <div className={style.header}>
          <div className={style['header-left']}>
            <span className={style['header-title']}>
              <img src={require('../assets/img/logo.png')} />
              <span>{companyName}</span>
            </span>
          </div>

          <div className={style['header-menu']}>
            <Space size='middle'>
              <NavLink activeClassName={style.active} to='/admin/case-list'>案件列表</NavLink>
              <NavLink activeClassName={style.active} to='/admin/open-list'>开庭时间</NavLink>
              <NavLink activeClassName={style.active} to='/admin/meeting-list'>视频庭审</NavLink>
              <NavLink activeClassName={style.active} to='/admin/course-list'>线上课程</NavLink>
              {/* <NavLink activeClassName={style.active} to='/admin/case-list'>呈批消息</NavLink> */}
              {/* <NavLink activeClassName={style.active} to='/admin/case-list'>仲裁员酬金</NavLink> */}
              <NavLink activeClassName={style.active} to='/admin/user-info'>个人信息维护</NavLink>
            </Space>
          </div>

          <Dropdown overlay={this.renderMenu()} trigger={['click']}>
            <span className={style['header-right']}>
              <span className={style['header-person__name']}>{userInfo.realname}</span>
              <Avatar icon={<UserOutlined />} />
            </span>
          </Dropdown>
        </div>
        <div className={style.body}>
          {/* <BrowserRouter> */}
          <Switch location={this.props.location}>
            <Route exact path='/admin/detail/:id' component={Detail} />
            <Route exact path='/admin/case-list' component={CaseList} />
            <Route exact path='/admin/open-list' component={OpenList} />
            <Route exact path='/admin/user-info' component={UserInfo} />
            <Route exact path='/admin/meeting-list' component={MeetingList} />
            <Route exact path='/admin/course-list' component={CourseList} />
            <Route exact path='/admin/course/:id' component={CourseDetail} />
            <Route exact path='/admin/course/:id/:mediaid' component={CourseDetailVideo} />
            <Route path='*' render={() => <Redirect to='/admin/case-list' />} />
          </Switch>
          {/* </BrowserRouter> */}
        </div>
      </div>
    )
  }
}

const mapState = mapStateToProps(function (state) {
  return {
    userInfo: state.basic.userInfo
  }
})

const mapDispatch = mapDispatchToProps(function (dispatch) {
  return {
    setUserInfo: d => dispatch(setUserInfo(d)),
    setToken: d => dispatch(setToken()),
    setSendTime: d => dispatch(setSendTime(d))
  }
})

export default connect(mapState, mapDispatch)(Admin)
