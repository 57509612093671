import { $get } from '@/assets/js/authRequest'

export async function getListRequest (type) {
  const condition = [{ k: 'status', o: '=', v: '已发布' }]
  if (type && type !== '全部') {
    condition.push({ k: 'course_type', o: '=', v: type })
  }
  const res = await $get('/api/arbitrator/course', {
    params: {
      page_size: 1000,
      current: 1,
      condition: JSON.stringify(condition)
    }
  })
  return res.data.list
}
