// import * as T from './actionTypes'

const initialState = {

}

// 设置基本信息
function reducer (state = initialState, action) {
  switch (action.type) {
    default:
      return state
  }
}

export default reducer
