import React from 'react'

import {
  Route,
  Switch,
  withRouter,
  Router as R
} from 'react-router-dom'

import './Router.less'
import { RouterConfig } from './RouteConfig'
import { createBrowserHistory } from 'history'

const Routes = withRouter(({ location, history }) => {
  return (
    <Switch location={location}>
      {RouterConfig.map((config, index) => (
        <Route exact key={index} {...config} />
      ))}
    </Switch>
  )
})

export const history = createBrowserHistory()

export default class Router extends React.PureComponent {
  render () {
    return (
      <R history={history}>
        <Routes />
      </R>
    )
  }
}
