import { $get } from '@/assets/js/authRequest'

// 获取视频列表
export async function getListRequest (status) {
  const condition = []
  if (status === '进行中') {
    condition.push({
      k: 'm.status',
      o: 'in',
      v: '预约,实名认证,预备,活体认证,会议开始,会议结束,签名授权'
    })
  } else if (status === '已完成') {
    condition.push({
      k: 'm.status',
      o: 'in',
      v: '笔录签名,完成'
    })
  }
  const res = await $get('/api/xd/meeting/myMeeting', {
    params: {
      page: 1,
      limit: 10000,
      condition: JSON.stringify(condition)
    }
  })
  if (res.status !== 1) {
    throw new Error(res.message)
  }
  const list = res.data.list.map((li, i) => {
    li.order = i + 1
    return li
  })
  return list
}
