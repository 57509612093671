import React from 'react'
import axios from 'axios'
import { message } from 'antd'
import { setToken } from '../redux/actions'
const instance = axios.create({
  headers: {
    'Content-Type': 'application/json'
  },
  timeout: 1000 * 30
})
// axios.defaults.timeout = 1000 * 30;
// axios.defaults.headers['Content-Type'] = 'application/json';

/**
 * 请求方法
 * @param {*} type 请求类型
 */
function request (type) {
  return function (url, params = {}) {
    const { token, userInfo = {} } = this.props
    instance.defaults.headers.Authorization = `Bearer ${token}`
    const that = this
    const data = {
      uid: userInfo.id
      // login_realname: userInfo.realname,
      // login_mobile: userInfo.mobile,
      // login_group_id: userInfo.group_id
    }
    if (type === 'post' || type === 'put') {
      if (params instanceof FormData) {
        params.append('uid', userInfo.id)
      } else {
        params = Object.assign(params, data)
      }
    } else {
      if (!params.params) {
        params.params = {}
      }
      params.params = Object.assign(params.params, data)
    }
    return new Promise((resolve, reject) => {
      instance[type](url, params)
        .then(res => {
          resolve(res.data)
        })
        .catch(e => {
          const { status, data } = e.response || { status: 504 }
          if (status === 401 || (status === 500 && data.message === '登录超时')) {
            if (!this.props.token) {
              return reject(e)
            }
            that.props.setToken(undefined)
            message.error(token ? '登录超时' : '请先登录', 2)
            setTimeout(() => {
              that.props.history.push('/login')
            }, 2000)
            return reject(e)
          }
          if (status === 504) {
            message.error('网络异常', 2)
          } else if (typeof data.message === 'string') {
            message.error(data.message, 2).then(null, null)
          } else if (status >= 400 && status < 500) {
            message.error('系统网络异常，请联系网络管理员', 2).then(null, null)
          } else {
            message.error('服务器异常，请联系管理员', 2).then(null, null)
          }
          reject(e)
        })
    })
  }
}
export default class Component extends React.Component {
  $get = request('get')
  $post = request('post')
  $put = request('put')
  $delete = request('delete')
  state = {}
  componentWillMount () {
    if (!this.props.token) {
      return this.props.history.push('/login')
    }
    if (this.props.userInfo && !this.props.userInfo.is_auth) {
      // this.props.history.push('/verifaction')
    }
  }
}

export function mapStateToProps (fn = () => {}) {
  return function (state) {
    const basic = {
      userInfo: state.basic.userInfo,
      token: state.basic.token
    }
    const newState = fn(state)
    return {
      ...basic,
      ...newState
    }
  }
}

export function mapDispatchToProps (fn = () => {}) {
  return function (dispatch) {
    const basic = {
      setToken: (i) => dispatch(setToken(i))
    }
    const newState = fn(dispatch)
    return {
      ...basic,
      ...newState
    }
  }
}
