import * as T from './actionTypes'

const initialState = {
  userInfo: {},
  token: '',
  sendTime: undefined,
  arbitratorInfo: {}
}

// 设置基本信息
function reducer (state = initialState, action) {
  switch (action.type) {
    case T.SET_USERINFO:
      return {
        ...state,
        userInfo: action.data
      }
    case T.SET_TOKEN:
      return {
        ...state,
        token: action.data
      }
    case T.SET_SEND_TIME:
      return {
        ...state,
        sendTime: action.data
      }
    case T.SET_LOGIN_MOBILE:
      return {
        ...state,
        mobile: action.data
      }
    case T.SET_ARBITRATOR_INFO:
      return {
        ...state,
        arbitratorInfo: action.data
      }
    default:
      return state
  }
}

export default reducer
